.about-us-container {
  top: 3500px;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(44, 44, 44);
}

.about-us-content {
  margin-top: 3rem;
  max-width: 1280px;
  width: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;

  color: rgba(0, 0, 0, 0.7);

  font-size: 1.1rem;
  font-weight: 300;
  /* text-align: center; */
}

.about-us-section h2 {
  text-align: center;
  font-size: 1.7rem;
  letter-spacing: -0.05em;

  text-transform: uppercase;
}

.sub-section {
  /* padding: 20px; */
  margin-top: 20px;
  margin-bottom: 30px;
}

.sub-heading {
  font-size: 22px;
  font-weight: bold;
  margin: 1.2rem;
  text-align: center;
}

.quote {
  margin-right: 10px;
  font-size: 35px;
}

.quote-small {
  margin-right: 8px;
  font-size: 20px;
}

.quoted-text {
  text-align: center;
  max-width: 920px;
}

.about-us-content .sub-section p {
  margin-bottom: 1.5rem;
}

.about-us-content .list {
  text-align: center;
}

.diamond {
  margin-top: 1rem;
  font-size: 3rem;
  color: #afe1fd;
}

/* .aWave {
  position: relative;
  width: 100%;
  height: 120px;
}

.custom-shape-divider-top-1624683434 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1624683434 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-top-1624683434 .shape-fill {
  fill: var(--color-secondary);
} */
