.services-container {
  top: 3500px;
  padding-top: 100px;
  /* background-color: #eaf8ff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-content {
  top: 0;
  max-width: 1280px;
  width: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px;
  /* align-items: left; */
  /* color: #051923; */
  margin: 2px 5px;
  /* font-size: 18px; */
}

.list {
  padding-left: 10px;
  /* border: solid 1px green; */
}
.list > ul {
  padding-left: 30px;
}
.list > ul > li {
  padding-left: 20px;
  /* border: solid 1px green; */
}
::marker {
  padding: 10px;
  /* font-family: "Font Awesome 5 free";
  content: "\f13d"; */
  color: "#00A6FB";
}

.section {
  margin-bottom: 30px;
  font-weight: 300;
}

/* .section h2 {
  color: #0582ca;
} */

.section-heading {
  margin-bottom: 10px;
  font-weight: 500;
}

.section-content {
  margin-left: 10px;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* text-align: center; */
  color: rgba(0, 0, 0, 0.7);
}

.section-content h3 {
  text-align: center;
}

.card {
  display: flex;
  flex-direction: column;
  /* background-color: #fff; */
  /* box-shadow: 2px 0.8rem 1rem rgba(0, 0, 0, 0.05); */
  padding: 30px 25px;
}

.summary-container {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: 25px;
  grid-gap: 90px;
  max-width: 1280px;
  justify-content: center;
  align-items: center;

  padding: 5px 50px;
}

.summary {
  padding: 10px;
  /* background-color: #003554; */
  /* color: #fff; */
  padding: 20px;
  height: 15vh;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 19rem;
  min-width: 15rem;
  align-items: center;
}

.summary > div > a {
  /* background-color: #00a6fb; */
  text-decoration: none;
  padding: 5px 10px;
  /* margin-top: 15px; */
  border-radius: 15px;
  color: white;
  transition: ease-out 250ms;
}

.summary .readMore {
  background-color: #00a6fb;
  height: 40px;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

/* .summary > div > a:hover {
  background-color: #0582ca;
} */

.summary .readMore:hover {
  background-color: #0582ca;
}

.summary .heading {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
}

.section-content .list {
  display: grid;
  grid-template-columns: auto;
  text-align: center;
}

.list .item {
  background-color: #c5e8fc;
  margin: 5px 0;
  padding: 1rem;
}

.section-content h3 {
  margin: 2rem 0;
}

.hands {
  font-size: 5rem;
  color: #c5e8fc;
}

@media (max-width: 991px) {
  .summary-container {
    display: flex;
    flex-direction: column;
  }
}
