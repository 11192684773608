/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Open+Sans:wght@300;400;600;700;800&display=swap"); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
  /* font-family: "Josefin Sans"; */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* font-family: Arial, Helvetica, sans-serif; */
}

:root {
  --color-primary: #0582ca;
  --color-primary-light: #00a6fb;
  --color-primary-dark: #006494;
  --color-secondary: #003554;
  --color-secondary-dark: #051923;
  --color-background: #eaf8ff;
}

.container {
  /* top: 3500px; */
  /* padding-top: 100px; */
  /* background-color: var(--color-background); */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1200px;
}

.menuIcon {
  height: 3vmin;
  width: 3vmin;
}

.navbar-logo {
  font-weight: bold;
}
