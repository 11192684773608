.news-heading {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  /* justify-content: center; */
  /* background-color: cyan; */
  /* min-height: 80px; */
  height: 80px;
}

.news-content {
  display: none;
  padding: 20px;
}

.news-content h1 {
  margin-bottom: 80px;
}
.news-content p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.news-article {
  border: 1px solid #afe1fd;
  padding: 10px;
  margin: 20px;
}

.details-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-icon {
  /* margin: 15px 15px 30px 15px; */
  width: 60px;
  height: auto;
  padding: 10px;
  color: rgba(0, 0, 0, 0.3);
}

.news-heading .icon-container {
  height: 110px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #afe1fd;
}

.news-heading .dropdown-container {
  height: 110px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-hide {
  /* margin: 15px; */
  padding: 5px;
  width: 30px;
  height: auto;
  background-color: #afe1fd;
  border-radius: 50%;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  /* align-self: flex-end; */
}

.show-hide:hover {
  background-color: var(--color-primary-light);
  color: #fff;
}

.news-content .description {
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 14px;
}

.news-heading h3 {
  margin: 20px 0;
  text-align: center;
  font-size: 18px;
}

@media screen and (max-width: 960px) {
  .news-heading h3 {
    font-size: 16px;
  }

  .news-heading {
    height: auto;
  }
}
