.home-container {
  /* top: 3500px; */
  /* padding-top: 100px; */
  /* background-color: #eaf8ff; */
  /* background-color: var(--color-background); */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-content {
  top: 0;
  max-width: 1280px;
  width: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px;

  color: #051923;
  margin: 2px 5px;

  font-size: 1.2rem;
}
.home-content div p {
  margin-bottom: 5px;
  padding: 10px;
}

.home-info-btn {
  background-color: #003554;
  padding: 10px 7px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  /* position: relative; */
  /* border-radius: 15px; */
  transition: 1s;
  transition-property: background-color;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 140px;
  text-align: center;
  margin-left: 10px;
}

/* .home-content h2 {
  color: #0587d1;
  font-size: 50px;
  text-transform: uppercase;
  line-height: 55px;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 2px;
}

.home-content h2 span {
  color: #006494;
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0px;
} */

/* .home-info-btn:hover {
  background-color: #00a6fb;
} */

.grid-container {
  display: flex;
  /* display: grid; */
  width: 1280px;
  flex-direction: column;
  /* grid-template-columns: auto auto; */
  padding: 10px;
  /* background-color: green; */
  /* margin-left: 0; */
  /* grid-gap: 10px; */
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2.5rem;
}

.section {
  /* padding-bottom: 3rem; */
  margin-bottom: 50rem;
  /* color: rgba(0, 0, 0, 0.7); */
  font-size: 1.1rem;
  /* font-size: 1.1rem; */
  /* font-weight: 100; */
}

/* .home-heading {
  font-size: 2rem;
  text-align: center;
  margin-top: 3rem;
  color: var(--color-primary-dark);
  font-weight: 400;
} */

.introduction {
  /* text-align: center; */
  margin-top: 3rem;
  font-size: 1.1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 1.6rem;
  margin: 3.5rem 0 1rem;
  color: rgba(0, 0, 0, 0.7);
}

.title-heading {
  /* text-align: center; */
  margin-bottom: 5rem;
  color: rgba(0, 0, 0, 0.7);
}
.title-heading h3 {
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
}

.title-heading h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 2rem;
  margin: 3.5rem 0 1rem;
  text-transform: uppercase;
  text-align: center;
}

.title-heading p {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 1.5rem;
}

.medal {
  margin-top: 5rem;
  font-size: 5rem;
  /* color: rgba(0, 0, 0, 0.3); */
  color: #afe1fd;
}

.icon-container {
  text-align: center;
}

.gem {
  font-size: 2rem;
  color: var(--color-primary-light);
}

.title-heading p {
  /* text-transform: lowercase; */
  /* text-align: justify; */
}

.activities-grid-item {
  height: 35rem;
  padding: 3rem;
  color: #dfdede;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.activities-grid-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(
    135deg,
    rgba(0, 166, 251, 0.9),
    rgba(0, 100, 148, 0.7) 100%
  ); */
  background-color: var(--color-secondary);
  z-index: -1;
}

.activities-grid-item i {
  font-size: 4rem;
  color: #eb648c;
}

.activities-grid-item h1 {
  font-size: 1.7rem;
  text-transform: uppercase;
  margin: 2rem 0;
}

.grid-item {
  padding: 50px 10px;
  text-align: justify;
  color: #003554;
  font-size: 1.1px;
  font-weight: normal;
  line-height: 1.5rem;
}

.grid-item > h2 {
  color: red;
  padding-bottom: 10px;
  font-size: 22px;
}

.para {
  padding-top: 10px;
}

.blog-container {
  /* background-color: rgb(3, 170, 255, 0.08); */
  height: 800px;
  width: 1280px;
}
.blog-container > h2 {
  /* font-size: 26px;
  padding-top: 20px;
  padding-left: 50px;
  color: #006494; */
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.7);
}

.blogs {
  /* background-color: rgb(3, 170, 255, 0.08); */
  /* height: 500px; */
  /* background-color: rgb(3, 170, 255, 0.08); */
  width: 1280px;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 20px;
}

.blog-item {
  margin: 15px;
  background-color: #ffff;
  color: #003554;
  font-size: 13px;
  padding: 50px;
}

.highlight {
  background-color: #00a6fb;
  padding: 2px 7px;
  margin-right: 3px;
  color: #fff;
  font-size: 2.1rem;
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  /* .home-content {
      top: 2px;
    } */

  /* 

  .grid-container {
    
    margin-left: 40px;
  } */

  .home-content > h2 {
    font-size: 25px;
    line-height: 30px;
    margin-left: 20px;
  }

  /* .grid-container {
    margin-left: 50px;
    margin-right: 50px;
  } */
  .text-heading {
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    position: relative;
  }

  .grid-container {
    display: flex;
    width: 100%;
    /* grid-template-columns: auto; */
    flex-direction: column;
  }
  .activities-grid {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2.5rem;
  }

  .blog-container > h2 {
    font-size: 18px;
    padding: 20px;
  }

  /* .grid-item > h2 {
      font-size: 18px;
    } */
  .blog-container {
    width: 100%;
    height: auto;
  }
  .blogs {
    width: 100%;
    grid-template-columns: auto;
  }
  .blog-item {
    padding: 50px;
  }

  .activities-grid-item {
    width: 100%;
    height: auto;
  }
}
