.footer {
  position: relative;
  max-width: 1280px;
  height: auto;
  width: 100%;
  padding: 50px 100px;
  background: #051923;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}

.footer-container .about-us {
  width: 40%;
  padding-right: 50px;
}

.footer-container .about-us h2 {
  position: relative;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer-container .about-us h2:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #00a6fb;
}

.footer-container .about-us p {
  color: #999;
}

.footer-container .quick-links {
  width: 30%;
}

.footer-container .quick-links h2 {
  position: relative;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer-container .quick-links h2:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #00a6fb;
}

.footer-container .quick-links li {
  list-style: none;
  margin-bottom: 10px;
}
.footer-container .quick-links li a {
  text-decoration: none;
  color: #999;
}

.footer-container .quick-links li a:hover {
  color: #00a6fb;
}

.footer-container .contact {
  width: calc(35% - 60px);
  margin-right: 0 !important;
}

.footer-container .contact h2 {
  position: relative;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer-container .contact ul {
  list-style: none;
  color: #fff;
}

.footer-container .contact .info {
  position: relative;
}

.footer-container .contact li {
  text-decoration: none;
  margin-bottom: 10px;
  color: #999;
}

.footer-container .contact li > span:hover {
  color: #fff;
}

.footer-container .contact h2:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background: #00a6fb;
}

.footer .copyright {
  color: #999;
  font-size: 15px;
  width: 100%;
  padding: 18px 100px;
  text-align: center;
}

.social-media {
  margin-top: 20px;
  display: flex;
  position: relative;
}

.social-media ul {
  text-decoration: none;
}

.social-media li {
  list-style: none;
  width: 40px;
  height: 40px;
  /* background: #222; */
  background: #002438;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 4px;
}

.social-media li:hover {
  /* background-color: #003554; */
  background-color: #fff;
}

.social-media-icon {
  text-decoration: none;
  color: #00a6fb;
  width: 30px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 4px;
}

.contact-icon {
  margin-right: 10px;
  margin-bottom: 0px;
  width: 18px;
  height: auto;
  color: #00a6fb;

  align-items: center;
}

.lic-section {
  color: #fff;
  display: flex;
  width: 100%;

  flex-direction: row;
  flex-basis: auto;
  align-items: center;
}

.lic-section div {
  padding-right: 10px;
  /* margin: 0 0 0 100px; */
  /* margin-right: 100px; */
}

.lic-section div a {
  text-decoration: none;
  color: #999;
}

.lic-section > Link {
  text-decoration: none;
  /* color: #999; */
  color: red;
}

@media screen and (max-width: 960px) {
  .footer {
    padding: 40px;
  }
  .footer-container {
    flex-direction: column;
  }
  .footer-container .about-us {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
    padding-right: 0px;
    padding-left: 10px;
  }
  .footer-container .quick-links {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    padding-right: 0;
    padding-left: 10px;
  }
  .footer-container .contact {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
    padding-right: 0;
    padding-left: 10px;
    margin-left: 0;
  }

  .footer .copyright {
    width: 100%;
    padding-left: 10px;
  }
}
