.ru-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.ru-content {
  max-width: 1280px;
  text-align: justify;
  margin-top: 3rem;
  font-size: 1.1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 1.6rem;
  margin: 3.5rem 0 1rem;
  color: rgba(0, 0, 0, 0.7);
}

.ru-content h1 {
  margin-bottom: 80px;
  text-align: center;
}

.ru-content h3 {
  margin: 20px 0;
  text-align: center;
}

.ru-content .description {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}

.ru-content {
  padding-left: 20px;
  padding-right: 20px;
}

.ru-content p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.list ul {
  display: block;
  /* margin-left: -5px; */
  margin-left: 10px;
}

.list ul .item {
  display: block;
  position: relative;
  background-color: #fff;
}

.list ul .item:not(:last-child) {
  margin-bottom: 2px;
}

.list ul .item:before {
  content: "";
  position: absolute;
  top: 2.2em;
  left: -30px;
  margin-top: -0.9em;
  background-color: #afe1fd;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

@media (max-width: 991px) {
}
