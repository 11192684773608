.rgn-news-content {
  max-width: 1280px;
  text-align: justify;
  margin-top: 3rem;
  font-size: 1.1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 1.6rem;
  margin: 3.5rem 0 1rem;
  color: rgba(0, 0, 0, 0.7);
  /* border: 1px solid #afe1fd; */
}

.rgn-news-content h1 {
  text-align: center;
}

.ru-sub-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: cyan; */
  height: 80px;
}

.ru-section-content {
  display: none;
}

/* .rgn-news-content{
    border:rgba(0, 0, 0, 0.3) 1px solid;
    
} */

@media screen and (max-width: 960px) {
  .rgn-news-content h1 {
    font-size: 35px;
    margin-bottom: 30px;
  }
}
