* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Roboto"; */
}

.navbar {
  /* background: linear-gradient(90deg, rgb(253, 253, 253) 0%, rgb(26, 23, 23) 100%); */
  background: #003554;
  /* background: rgba(0,53,84, 0.2); */
  /* background: rgba(0, 0, 0, 0.5); */

  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.wave {
  z-index: 9;
  width: 100%;
  height: 4vh;
  position: relative;
}

.custom-shape-divider-top-1627049274 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1627049274 svg {
  position: relative;
  display: block;
  width: calc(193% + 1.3px);
  height: 212px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1627049274 .shape-fill {
  fill: #ffffff;
}

.nav-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1200px;
}

.navbar-logo {
  /* color: #003554; */
  color: white;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.hamburger {
  color: #0582ca;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 50px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
  align-items: center;
}

.nav-links {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 2px;
}

.contact-btn {
  background-color: #00a6fb;
  padding-left: 18px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  border-radius: 70px;
  box-shadow: 2px 2px grey;
}

.contact-btn:hover {
  background-color: #ffffff;
  border-radius: 50px;
  color: #00a6fb;
}
.nav-links:hover {
  text-decoration: none;
  color: #00a6fb;
}

.rgnlogo {
  top: 10px;
  position: absolute;
  width: 100px;
  height: auto;
}

.logo {
  width: 300px;
  height: auto;
}

@media screen and (max-width: 960px) {
  .nav-item {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .contact-btn {
    border-radius: 0px;
  }

  .logo {
    width: 150px;
    height: auto;
  }
}
