.contact-us-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.c-contact {
  position: relative;
  min-height: 100vh;
  padding: 50px 100px;
  display: flex;

  align-items: center;
  flex-direction: column;
}

.c-contact .c-content {
  max-width: 800px;
  text-align: center;
}

.c-contact .c-content h2 {
  font-size: 36px;
  font-weight: 500;
  color: var(--color-secondary-dark);
}

.c-contact .c-content p {
  font-weight: 300;
  color: var(--color-secondary-dark);
}

.c-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.c-container .c-contact-info {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.c-container .c-contact-info .box {
  position: relative;
  padding: 20px 0;
  display: flex;
}

.c-container .c-contact-info .box .icon {
  min-width: 60px;
  height: 60px;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-primary-light);
  font-size: 22px;
}

.c-container .c-contact-info .box .text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  flex-direction: column;
  font-weight: 300;
}

.c-container .c-contact-info .box .text h3 {
  font-weight: 500;
  color: var(--color-primary);
}

.contactForm {
  width: 80%;
  padding: 40px;
  background: var(--color-secondary);
  color: #fff;
}

.contactForm h2 {
  font-size: 25px;
  color: var(--color-primary-light);
  font-weight: 500;
}

.contactForm .inputBox {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contactForm .inputBox input,
textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid var(--color-primary);
  outline: none;
  background-color: var(--color-secondary);
  color: #fff;
  resize: none;
}

.submitButton:hover {
  color: red;
}

.contactForm .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contactForm .inputBox input[type="submit"] {
  width: 100px;
  background: var(--color-primary-light);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
}

.contactForm .inputBox input[type="submit"]:hover {
  background-color: white;
  color: var(--color-primary-light);
}

@media (max-width: 991px) {
  .contact {
    padding: 5px;
  }
  .c-contact {
    padding: 20px;
  }

  .c-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-container .c-contact-info {
    margin-bottom: 40px;
    width: 100%;
  }

  .contactForm {
    margin-left: 0;
    width: 100%;
  }

  .c-contact .c-content {
    /* text-align: left; */
    width: 100%;
  }
}
