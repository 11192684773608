.hero-container {
  top: 0;
  left: 0;
  height: 70vh;
  background-color: #eaf8ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: myanimation 45s ease-in-out infinite;
}

@keyframes myanimation {
  0% {
    background: url("images/background16.jpg") center no-repeat;
    background-size: cover;
  }
  25% {
    background: url("images/background15.jpg") center no-repeat;
    background-size: cover;
  }
  50% {
    background: url("images/background16.jpg") center no-repeat;
    background-size: cover;
  }
  75% {
    background: url("images/background10.png") center no-repeat;
    background-size: cover;
  }
  100% {
    background: url("images/background16.jpg") center no-repeat;
    background-size: cover;
  }
}

.hero-btns {
  margin-top: 32px;
}

.hero-btn .btn {
  margin: 6px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 40px;
    margin-top: -150px;
  }

  .hero__img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768) {
  .hero-container {
    width: 100%;
    height: auto;
  }
  .hero-container > h1 {
    font-size: 40px;
    margin-top: -100px;
  }

  .hero__img {
    width: 100%;
    height: auto;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
