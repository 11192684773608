.hero-container-a {
  top: 0;
  left: 0;
  height: 70vh;
  background-color: #eaf8ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("images/background20.jpg") center no-repeat;
  background-size: cover;
}

.mydiv {
  width: 100%;
  height: 100%;
  color: black;
  font-weight: bold;
  animation: myanimation 10s infinite;
}

/* .hero-content {
    margin: 50px;
  } */

.hero__img {
  /* height: 200px;
    width: 1200px; */
  /* border: 5px solid darkolivegreen;
    border-radius: 1rem; */
  display: block;
  width: 1280px;
  height: 400px;
  /* opacity: 0.4; */
}

.hero-content.h2 {
}

.hero-btns {
  margin-top: 32px;
}

.hero-btn .btn {
  margin: 6px;
}

/* .hero-label { */
/* font-size: 30px;
    line-height: 100px;
    padding-left: 50px;
    margin: 0; */
/* height: 4rem; */
/* margin-top: -4rem; */
/* color: white;
    background-color: hsla(0, 0%, 0%, 0.6);
    position: relative;
    transform: translateY(-100px);
    letter-spacing: 2px; */
/* } */

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 40px;
    margin-top: -150px;
  }

  .hero__img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768) {
  .hero-container > h1 {
    font-size: 40px;
    margin-top: -100px;
  }

  .hero__img {
    width: 100%;
    height: auto;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
