.article-details {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  align-items: center;
  color: var(--color-primary-light);
  margin-top: 5px;
}
.article-details span {
  margin-right: 10px;
}
.article-details .property {
  margin-right: 40px;
  /* text-transform:capitalize; */
}

.author {
  padding: 4px;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 50%;
  font-size: 30px;
  background-color: #afe1fd;
}
