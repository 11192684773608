.policy-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.policy-content {
  max-width: 1280px;
  text-align: justify;
  margin-top: 3rem;
  font-size: 1.1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  line-height: 1.6rem;
  margin: 3.5rem 0 1rem;
  color: rgba(0, 0, 0, 0.7);
}

.policy-content h1 {
  margin-bottom: 40px;
  text-align: center;
}

.policy-content h3 {
  /* text-align: center; */
  margin: 20px 0;
}

.list-container {
  text-align: left;
  /* padding-left: 10px; */
  /* padding-left:-20px; */
  padding-right: 50px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.u-list {
  text-align: center;
}

/* .policy-content .list {
  display: flex;
  flex-direction: column;
  
  width: 100%;
  flex-wrap: wrap;
} */

.policy-content .list .item {
  /* background-color: #c5e8fc; */
  background-color: white;
  margin: 2px 0;
  padding: 1rem;
}

.list ul {
  display: block;
  /* margin-left: -5px; */
  margin-left: 10px;
}

.list ul .item {
  display: block;
  position: relative;
}

.list ul .item:not(:last-child) {
  margin-bottom: 2px;
}

.list ul .item:before {
  content: "";
  position: absolute;
  top: 2.2em;
  left: -30px;
  margin-top: -0.9em;
  background-color: #afe1fd;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.list-container ol {
  display: block;
  /* margin-left: -5px; */
  margin-left: -30px;
}

.list-container ol li:not(:last-child) {
  margin-bottom: 10px;
}

.list-container ol li {
  list-style-type: none;
  counter-increment: item;
}

.list-container li:before {
  content: counter(item);
  margin-right: 15px;
  font-size: 80%;
  /* background-color: #f9dd94; */
  background-color: #afe1fd;
  color: white;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
}

.email {
  color: var(--color-primary);
}
